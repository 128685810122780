// src/components/MemoryCardGame.js
import React from 'react';

function MemoryCardGame() {
  return (
    <div className="flex justify-center items-center h-full">
      <iframe
        src="/games/memory-card/index.html"
        title="Memory Card Game"
        className="w-full h-screen border-none"
      ></iframe>
    </div>
  );
}

export default MemoryCardGame;
