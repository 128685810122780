import React from 'react';
import './Home.css';

function Home() {
  return (
    <div className="home-page">
      
      <h1>Welcome to CryptoPlayZone: Play and Learn</h1>
      <h2>Your Gateway to Amazing Games</h2>
      <p>
        Discover a world of exciting games and challenges. Whether you're looking for memory games,
        puzzle games, or thrilling action games, we've got something for everyone. Join us now and start your adventure!
      </p>
      <div className="cta-buttons">
        <a href="/games" className="cta-link">Explore Games</a>
        {/* <a href="/profile" className="cta-link">View Profile</a> */}
      </div>
      <footer>
        <p>&copy; 2024 My Games Platform. All rights reserved. <a href="/terms">Terms of Service</a></p>
      </footer>
    </div>
  );
}

export default Home;
