/* eslint-disable import/order */
"use client";
import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import {
  argentWallet,
  coinbaseWallet,
  ledgerWallet,
  metaMaskWallet,
  rabbyWallet,
  rainbowWallet,
  safeWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { createConfig, http } from "wagmi";
import { taiko, taikoHekla, arbitrum, lineaSepolia, linea } from "wagmi/chains";

const mint = {
  id: 185,
  name: 'Mint Mainnet',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://rpc.mintchain.io'] },
  },
  blockExplorers: {
    default: { name: 'mintchain', url: 'https://explorer.mintchain.io' },
  },
};

const walletConnectProjectId = 'cfee1c46a7540f5a43cee18b0f4b0e36';

if (!walletConnectProjectId) {
  throw new Error(
    "WalletConnect project ID is not defined. Please check your environment variables."
  );
}

const connectors = connectorsForWallets(
  [
    {
      groupName: "Recommended",
      wallets: [
        metaMaskWallet,
        rainbowWallet,
        walletConnectWallet,
        ledgerWallet,
        rabbyWallet,
        coinbaseWallet,
        argentWallet,
        safeWallet,
      ],
    },
  ],
  { appName: "Next-Web3-Boilerplate", projectId: walletConnectProjectId }
);

// Fix missing icons
// const customZkSyncSepoliaTestnet = { ...zkSyncSepoliaTestnet, iconUrl: zksync_logo.src };
// const customLinea = { ...linea, iconUrl: linea_logo.src };
// const customLineaTestnet = { ...lineaSepolia, iconUrl: lineaTesnet_logo.src };

const transports = {
  [taiko.id]: http(),
  [taikoHekla.id]: http(),
  [mint.id]: http(),
  [lineaSepolia.id]: http(),
  [linea.id]: http(),
  [arbitrum.id]: http(),
};

export const wagmiConfig = createConfig({
  chains: [arbitrum, linea, taiko, mint],
  connectors,
  transports,
  ssr: true,
});
