import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './components/Home';
import Games from './components/Games';
import MemoryCardGame from './components/MemoryCardGame';
import Profile from './components/Profile';
import "@rainbow-me/rainbowkit/styles.css";
import { Analytics } from "@vercel/analytics/react"

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-yellow-500 flex flex-col">
        <header className="bg-yellow-600 p-4 shadow-md">
          <div className="container mx-auto flex justify-between items-center">
            <div className="flex items-center space-x-4">
              <img src="/vvdf.svg" alt="CryptoPlayZone Logo" className="h-12 w-auto" />
              <h1 className="m-auto text-4xl font-bold text-black">CryptoPlayZone</h1>
            </div>
            <nav>
              <ul className="flex space-x-6">
                <li>
                  <Link to="/" className="text-lg text-black hover:underline">Home</Link>
                </li>
                <li>
                  <Link to="/games" className="text-lg text-black hover:underline">Games</Link>
                </li>
                {/* <li>
                  <Link to="/profile" className="text-lg text-black hover:underline">Profile</Link>
                </li> */}
              </ul>
            </nav>
          </div>
        </header>
        <main className="flex-grow container mx-auto mt-8 p-4">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/games" element={<Games />} />
            <Route path="/games/memory-card" element={<MemoryCardGame />} />
            {/* <Route path="/profile" element={<Profile />} /> */}
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;