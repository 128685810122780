import React, { useState } from 'react';
import './games.css';
import { CustomConnectButton } from './ConnectButton';
import { useAccount, useSendTransaction } from 'wagmi';
import { parseEther } from 'viem';

function Games() {
  const { isConnected } = useAccount();
  const [amount, setAmount] = useState('');
  const [copySuccess, setCopySuccess] = useState('');

  const { sendTransaction } = useSendTransaction();
  const devAddress = '0x0A7195d6e68f00Ab6f2A02a85c9b63f96384E23f';

  const handleSend = async () => {
    if (!amount) return;

    try {
      await sendTransaction({
        to: devAddress,
        value: parseEther(amount),
      });
      alert('Thank you for supporting the development!');
    } catch (error) {
      console.error('Error sending transaction:', error);
    }
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(devAddress);
      setCopySuccess('Copied!');
      setTimeout(() => setCopySuccess(''), 2000);
    } catch (err) {
      setCopySuccess('Failed to copy');
    }
  };

  const openExplorer = () => {
    window.open(`https://etherscan.io/address/${devAddress}`, '_blank');
  };

  const gameList = [
    { href: "/games/memory-card/index.html", img: "../games/memory-card/memory-card-game.png", alt: "Memory Card Game", name: "Memory Card Game" },
    { href: "/games/crypto-crush/index.html", img: "../games/crypto-crush/crypto-crush-game.png", alt: "Crypto Crush Game", name: "Crypto Crush Game" },
    { href: "/games/maze-game/index.html", img: "../games/maze-game/maze-game.png", alt: "Maze Game", name: "Maze Game" },
    { href: "/games/Hangman-Game/index.html", img: "../games/Hangman-Game/hangman-game.png", alt: "Hangman Game", name: "Hangman Game" },
    { href: "/games/snake-game/index.html", img: "../games/snake-game/snake-game.png", alt: "Snake Game", name: "Snake Game" },
    { href: "/games/quiz-game/index.html", img: "../games/quiz-game/quiz-game.png", alt: "Quiz Game", name: "Quiz Game" },
    { href: "/games/Tic-Tac-Toe-game/index.html", img: "../games/Tic-Tac-Toe-game/tic-tac-toe-game.png", alt: "Tic-Tac-Toe Game", name: "Tic-Tac-Toe Game" },
    { href: "/games/Word-Scramble/index.html", img: "../games/Word-Scramble/word-scramble-game.png", alt: "Word Scramble Game", name: "Word Scramble Game" },
    { href: "/games/reflection-game/index.html", img: "../games/reflection-game/reflection-game.png", alt: "Reflection Game", name: "Fast Click Game" },
    { href: "/games/whac-a-mole/index.html", img: "../games/whac-a-mole/whac-a-bear-game.png", alt: "Whac-a-Mole Game", name: "Whac-a-Bear Game" },
    { href: "/games/Pong-game/index.html", img: "../games/Pong-game/pong.png", alt: "Pong Game", name: "Pong Game" },
    { href: "/games/sokoban-miner/index.html", img: "../games/sokoban-miner/sokoban-miner.png", alt: "sokoban miner", name: "sokoban miner" },
    { href: "/games/Breakout Game/index.html", img: "../games/Breakout Game/image.png", alt: "Breakout Game", name: "Breakout Game" },
    { href: "/games/Rock-Paper-Scissors/index.html", img: "../games/Rock-Paper-Scissors/image.png", alt: "Rock-Paper-Scissors", name: "Pump-Dump-Hold" },
    { href: "/games/sodoku/index.html", img: "../games/sodoku/image.png", alt: "sodoku", name: "sudoku" },
    { href: "/games/TETRIS/index.html", img: "../games/TETRIS/image.png", alt: "Tetris", name: "Tetris" },
    { href: "/games/type chain/index.html", img: "../games/type chain/image.png", alt: "type chain", name: "type chain" },
    { href: "/games/Coin Slicer Game/index.html", img: "../games/Coin Slicer Game/image.png", alt: "Coin Slicer Game", name: "Coin Slicer Game" },
    { href: "/games/meme game/index.html", img: "../games/meme game/image.png", alt: "meme game", name: "meme creator " },
  ];

  return (
    <div className="games-page">
      <CustomConnectButton />
      
      {isConnected ? (
        <>
          {/* <div className="donation-section py-2 px-4 mb-6">
            <h2>Support Development</h2>
            <p>Your support helps us develop more games! Click 'Send ETH' or send to our address to contribute and make an impact.Every contribution makes a difference!</p>
            <div className="flex items-center mb-2">
              <input
                type="text"
                value={devAddress}
                readOnly
                className="text-teal font-bold py-2 px-4 rounded mr-2 cursor-pointer"
                onClick={openExplorer}
                title="Click to view on Etherscan"
              />
              <button 
                onClick={copyToClipboard}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Copy Address
              </button>
              {copySuccess && <span className="ml-2 text-green-500">{copySuccess}</span>}
            </div>
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Enter ETH amount"
              className="text-teal font-bold py-2 px-4 rounded mr-2"
            />
            <button 
              className="bg-purple-400 hover:bg-green-900 text-teal font-bold py-2 px-4 rounded"
              onClick={handleSend}
            >
              Send ETH
            </button>
          </div> */}
          <h1>Choose a Game</h1>
          <ul className="game-list">
            {gameList.map((game, index) => (
              <li key={index}>
                <a href={game.href} target="_blank" rel="noopener noreferrer">
                  <img src={game.img} alt={game.alt} />
                  <span>{game.name}</span>
                </a>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <div className="donation-section py-2 px-4 mb-6 text-sky-200">
          Connect your wallet to play Games and send ETH!
        </div>
      )}
    </div>
  );
}

export default Games;